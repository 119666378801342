<template>
  <div class="bg-white relative h-screen">
    <div class="inset-0 bg-white opacity-25 absolute"></div>
    <div
      class="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-32 xl:py-40"
    >
      <div class="w-full font-mono flex flex-col items-center relative z-10">
        <h1
          class="font-extrabold text-8xl text-center text-teal leading-tight mt-4"
        >
          OOPS!
        </h1>
        <h2
          class="font-extrabold text-2xl text-center text-teal leading-tight mt-4"
        >
          You&#x27;ve found a page that dosen&#x27;t exist
        </h2>
        <h3 class="font-semibold text-xl text-center text-teal">
          Breathe in, and on the out breath, go back and try again
        </h3>

        <p class="font-extrabold text-4xl my-44 text-teal animate-bounce">
          <router-link to="/"> Take me back!</router-link>
        </p>
        <heart-rate></heart-rate>
      </div>
    </div>
  </div>
</template>
